<template>
	<div>
		<div class="box_title flex sm-" style="justify-content: space-between;">
			<Title :title="titleList[$route.query.id - 1].title" :backShow="false" />
			<div class="flex">
				<button @click.stop="back" class="but">返回</button>
				<button @click.stop="choose" class="but">选取草稿</button>
			</div>
		</div>
		<div class="ct_box">
			<!-- 封面 -->
			<upLoadImg v-if="$route.query.id == 4" :imageUrl="$store.state.FillForm.cover_image" title="封面"
				@getImg="getImg" />
			<upLoadVideo v-if="$route.query.id == 6 || $route.query.id == 7" title="上传视频" @getVideo="getVideo" />

			<div class="flex input_box" style="justify-content:flex-start" v-show="$route.query.id == 7">
				<div class="title_box">
					<p class="title">视频预览</p>
				</div>
				<video :src="$store.state.FillForm.video_url" controls="controls" class="contentVideo"></video>
			</div>
			<el-form :model="$store.state.FillForm" :rules="rules" ref="$store.state.FillForm">
				<el-form-item prop="title">
					<!-- <el-input v-model="$store.state.FillForm.title" placeholder="标题建议25字符以内，字符过多的，媒介修改标题不通知"></el-input> -->
					<div class="flex input_box">
						<div class="title_box">
							<p class="title"><span>*</span>稿件标题</p>
						</div>
						<!-- <input class="input_text" v-model="$store.state.FillForm.title"
							placeholder="标题建议25字符以内，字符过多的，媒介修改标题不通知" /> -->
						<el-input class="title_input" v-model="$store.state.FillForm.title"
							placeholder="标题建议25字符以内，字符过多的，媒介修改标题不通知"></el-input>
						<span class="num">{{ $store.state.FillForm.title.length }}/50</span>
					</div>
				</el-form-item>
			</el-form>

			<fillTimer @get_timer="get_timer" />

			<div class="flex input_box">
				<div class="title_box">
					<p class="title">转载链接</p>
				</div>
				<input class="input_text" v-model="$store.state.FillForm.url" placeholder="若填写稿件转载链接，则首选以链接内容发布" />
			</div>
			<!-- 公众号转载链接 -->
			<div class="flex input_box" v-if="$route.query.id == 4">
				<div class="title_box">
					<p class="title">原文链接</p>
				</div>
				<input class="input_text" v-model="$store.state.FillForm.original_url"
					placeholder="例如:https://www.xxx.com/" />
			</div>
			<div class="flex input_box">
				<div class="title_box">
					<p class="title">备注信息</p>
				</div>
				<input class="input_text" v-model="$store.state.FillForm.remark" placeholder="发稿有什么特殊要求请写上，没有请不要填写" />
			</div>
			<div>
				<div class="flex input_box2">
					<div class="title_box">
						<p class="title"><span></span>上传文档</p>
					</div>
					<div class="upload_box">
						<el-upload accept="pdf,doc,docx" :show-file-list="true" @on-success="uploadFn" @on-error="err"
							:limit="1" class="upload-demo" drag :action='uploadAPI' :data=formData
							:on-success="handleAvatarSuccess" :headers="headers" :multiple="false"
							:on-exceed="handleExceed" :before-upload="beforeAvatarUpload">
							<div class="el-upload__text">
								<!-- <p class="text">将文件拖拽到此区域上传</p> -->
								<img src="@/assets/wordOp/upload.png" alt="" style="margin: 1rem 0" />
								<div class="el-upload__tip" slot="tip">
									仅支持.docx,Office2007、2010、20132016
								</div>
								<div class="el-upload__tip" slot="tip">
									文件大小不可超过10MB!
								</div>
								<div style="color: rgba(255, 0, 0, 1);" class="el-upload__tip" slot="tip">
									因文档格式多样，上传word文档后，请仔细检查图片和内容是否有缺失！
								</div>
							</div>
							<!-- <i class="el-icon-upload"></i> -->
							<div class=""></div>
							<!-- <em>点击上传</em> -->
						</el-upload>
					</div>
				</div>

				<div class="flex input_box">
					<div class="title_box2">
						<p class="title"><span></span>稿件内容</p>
					</div>
					<div style="width: 50%;">
						<vue-ueditor-wrap id="tinymce" v-model="$store.state.FillForm.content"
							:config="myConfig"></vue-ueditor-wrap>
					</div>
				</div>
				<div class="input_box flex">
					<div class="title_box">
						<p class="title"><span></span></p>
					</div>
					<button class="draft_but" @click="adddRaft">存为草稿</button>
				</div>
			</div>
		</div>
		<SetMenuButNav />
	</div>
</template>

<script>
import Title from '@/components/UserInfoTitle.vue'
import VueUeditorWrap from 'vue-ueditor-wrap'
import SetMenuButNav from '@/components/SetMenuButNav.vue'
import { mapState } from 'vuex'
import { empty } from '@/util/emptyFillDate'
import { fill_title_rules } from '@/util/fill_title_rules'
import fillTimer from '@/components/fillTimer.vue'
export default {
	name: '',
	computed: {
		...mapState(['FillForm', 'shopObj', 'ActiveChild'])
	},

	components: {
		Title,
		VueUeditorWrap,
		SetMenuButNav,
		fillTimer
	},
	created() { },
	mounted() {
		this.headers = {
			'token': this.$user_info.token
		}
		this.getDetail()
		// this.getmonthValue()
		this.tabShow()

	},
	data() {
		return {

			user_info: {},
			titleList: [{
				id: 1,
				title: '资讯门户发布'
			},
			{
				id: 2,
				title: '自媒体发布'
			},
			{
				id: 3,
				title: '纸媒介发布'
			},
			{
				id: 4,
				title: '公众号发布'
			},
			{
				id: 5,
				title: '微博发布'
			},
			{
				id: 6,
				title: '小红书发布'
			},
			{
				id: 7,
				title: '短视频发布'
			},
			{
				id: 99,
				title: '套餐发布'
			},
			],
			myConfig: {
				// 编辑器不自动被内容撑高
				autoHeightEnabled: false,
				// 初始容器高度
				initialFrameHeight: 400,
				// 初始容器宽度
				initialFrameWidth: '100%',
				// 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
				serverUrl: `/api/common/getupload?token=${this.$user_info.token}`,
				// UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
				UEDITOR_HOME_URL: '/UEditor/',
				initialContent: '1.所提供文章必须符合国家广告法内容，若文章存在违规被删除，本平台不予处理；<br/>2.Word文档上传后请仔细检查内容是否有误，一经发布无法撤回和修改，请确认后再操作；<br/>3.署名、作者名、免责声明等特殊内容不保证，网媒没标明不包时效的，默认1个月。<br/>4.图片文件最大上传不超过3MB；<br/>5.视频文件最大上传不超过20MB；',
				autoClearinitialContent: true,
				toolbars: [
					[
						"fullscreen",
						"source",	// html 源码
						"|",
						"undo",
						"redo",
						"|",
						"link",
						"unlink",
						"|",
						"simpleupload",
						"insertimage",
						"insertvideo",
						"|",
						"bold",
						"forecolor",
						"|",
						"justifyleft",
						"justifycenter",
						"justifyright",
						"justifyjustify",
						"|",
						"fontfamily",
						"fontsize",
						"|",
						"removeformat",
						"formatmatch",
						"autotypeset",
					]
				],
			},
			uploadAPI: `/api/common/upload`,
			headers: {},
			formData: {
				type: 'read'
			},
			rules: {
				title: [
					{ required: true, message: '请输入标题名称', trigger: 'blur' },
					{ min: 1, max: 50, message: '标题长度在 1 到 50 个字符', trigger: 'blur' }
				],
			},

		}
	},
	methods: {
		get_timer(time) {
			this.$store.state.FillForm.limit_time = time
		},
		getImg(url) {
			this.$store.state.FillForm.cover_image = url
		},
		getVideo(url) {
			this.$store.state.FillForm.video_url = url
			if (this.$route.query.id == 6) {
				this.$store.state.FillForm.content += `<video controls="controls" src="${url}" class="contentVideo"></video><p><br/></p>`
				// let d = document.getElementById("contentVideo");
				// console.log(d);
			}
		},

		textInput() {
		},
		handleAvatarSuccess(res, file) {
			if (res.code) {
				this.$store.state.FillForm.content = res.data.content
				this.$store.state.FillForm.file = res.data.url
				// this.$store.state.FillForm.file = res.data.url
			}

		},
		beforeAvatarUpload(file) {
			let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);

			const extension = testmsg === "pdf" || testmsg === "doc" || testmsg === "docx";

			if (!extension) {
				this.$message.error('上传图片类型只能是 PDF,DOC,DOCX!')
			}
			const is10M = file.size / 1024 / 1024 < 10;
			//限制文件上传大小
			if (!is10M) {
				this.$message.error("上传文件大小不能超过 10MB!");
				return false
			}

			return extension
		},
		handleExceed(files, fileList) {
			this.$message.warning(
				`当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
				} 个文件`
			)
		},
		uploadFn(response, file, fileList) {
			console.log(response, file, fileList)
		},
		beforeRemove(file, fileList) {
			return this.$confirm(`确定移除 ${file.name}？`)
		},
		err(err, file, fileList) {
			console.log(err, file, fileList)
		},
		// 选取草稿
		choose() {
			this.$store.commit('childTab', '草稿箱')
			return this.$router.push('/user/draft')
		},
		// 存为草稿	
		adddRaft() {
			let data = {}
			const titleStatus = fill_title_rules(this.$store.state.FillForm.title)
			console.log(titleStatus);
			if (!titleStatus) {
				return
			}
			if (this.$store.state.shopObj[0]) {
				data.category_id = this.$store.state.shopObj[0].category_id
			} else if (this.$route.query.id && this.$route.query.id != 8) {
				data.category_id = this.$route.query.id
			} else {
				data.category_id = 0
			}
			console.log(data.category_id);

			this.$store.state.shopObj.forEach((item, index) => {
				if (item.medium_ids > 0) {
					this.$store.state.FillForm.medium_ids += `${item.medium_ids},`
				}
				if (item.package_ids > 0) {
					this.$store.state.FillForm.package_ids += `${item.package_ids},`
				}
			})
			if (this.$store.state.FillForm.medium_ids != '') {
				data.medium_ids = ''
				data.medium_ids = this.$store.state.FillForm.medium_ids
			}
			if (this.$store.state.FillForm.package_ids != '') {
				data.package_ids = this.$store.state.FillForm.package_ids
			}
			if (this.$store.state.FillForm.time !== '') {
				data.limit_time = this.$store.state.FillForm.time
			}
			if (this.$store.state.FillForm.title != '') {
				data.title = this.$store.state.FillForm.title
			}
			if (this.$store.state.FillForm.content != '') {
				data.content = this.$store.state.FillForm.content
			}

			if (data.title == '' && data.content == '') {
				return this.$message({
					message: '标题和内容至少要填写一项',
					type: 'warning',
				})
			}
			data.file = this.$store.state.FillForm.file

			if (this.$store.state.FillForm.remark !== '') {
				data.remark = this.$store.state.FillForm.remark
			}
			if (this.$store.state.FillForm.url !== '') {
				data.url = this.$store.state.FillForm.url
			}
			if (this.$store.state.FillForm.cover_image !== '') {
				data.cover_image = this.$store.state.FillForm.cover_image
			}
			if (this.$store.state.FillForm.video_url !== '') {
				data.video_url = this.$store.state.FillForm.video_url
			}
			if (this.$store.state.FillForm.original_url != '') {
				data.original_url = this.$store.state.FillForm.original_url
			}
			this.curlPost('/api/users/draft/add', data).then(res => {
				if (res.data.code) {
					this.$message({
						message: '添加成功',
						type: 'success',
					})
					this.$store.state.shopObj = []
					this.$store.state.FillForm = empty()
					this.$store.commit('childTab', '草稿箱')
					return this.$router.push('/user/Draft')
				} else {

				}
			})
		},
		back() {
			this.$store.commit('childTab', '媒介资源')
			switch (this.$route.query.id) {
				case '1':
					this.$router.push({
						path: '/user/userMedia/News',
					})
					break;
				case '2':
					this.$router.push({
						path: '/user/userMedia/UserWeMedia',
					})
					break;
				case '3':
					this.$router.push({
						path: '/user/userMedia/UserPaperMedia',
					})

					break;
				case '4':
					this.$router.push({
						path: '/user/userMedia/UserOfficialAccounts',
					})
					break;
				case '5':
					this.$router.push({
						path: '/user/userMedia/UserWb',

					})

					break;
				case '6':
					this.$router.push({
						path: '/user/userMedia/UserRedBook',

					})

					break;
				case '7':
					this.$router.push({
						path: '/user/userMedia/UserShortVideo',

					})
					break;
				case '8' || '0':
					this.$store.commit('childTab', '媒介资源')
					this.$router.push({
						path: '/user/SetMenu/SetMenuList',

					})

					break;
				default:
					break;
			}

		},
		getDetail() {
			if (this.$route.query.mediaOrderId) {
				this.curlGet('/api/users/order/detail', {
					id: this.$route.query.mediaOrderId
				}).then(res => {
					if (res.data.code) {
						console.log(res.data.data);
						let data = res.data.data[0]
						this.$store.state.FillForm = {
							category_id: data.category_id,
							title: data.title,
							content: data.content,
							time: data.limit_time ? data.limit_time : '',
							file: '',
							url: data.url,
							video_url: data.video_url,
							original_url: data.original_url,
							remark: data.remark,
							medium_ids: '',
							package_ids: '',

						}

					}
				})
			}
		},
		tabShow() {
			switch (this.$route.query.id) {
				case '1':
					this.$store.commit('childTab', '资讯门户发布')
					break;
				case '2':
					this.$store.commit('childTab', '自媒体发布')

					break;
				case '3':
					this.$store.commit('childTab', '纸媒介发布')

					break;
				case '4':
					this.$store.commit('childTab', '公众号发布')

					break;
				case '5':
					this.$store.commit('childTab', '微博发布')

					break;
				case '6':
					this.$store.commit('childTab', '小红书发布')

					break;
				case '7':
					this.$store.commit('childTab', '短视频发布')
					break;
				case '8':
					this.$store.commit('childTab', '套餐发布')

					break;
				default:
					break;
			}
		}
	},
}
</script>

<style lang=scss scoped>
@import '@/scss/Fill';
</style>